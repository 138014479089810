// Must be the div id where you want the form to be loaded
export const INSTANCE_ID = 'urx-form';

// The required urx form-id to be embedded
export const FORM_ID = 'QTE-automatens1';
export const REQUEST_DEMO_FORM_ID = 'DEMO-automatens1';
export const FREE_SIGNUP_FORM_ID = 'urx-52830';

// The required locale must be in the following format country code-language code
export const LOCALE = 'us-en';
