import { useState, ReactElement, useEffect } from 'react';
import { useIntl } from 'react-intl';
import messages from '../locales/messages';
import 'src/common/style/common.scss';
import { ThanksModal, thanksTable } from 'src/common/utils/ThanksModal';

export interface thanksProps {
  queriesInput: number;
  recordsInput: number;
  filterChainsInput: number;
  monitorsInput: number;
  licensePriceMonthly: string;
  licensePriceYearly: string;
  packageName: string;
  bpName: string;
}

const Thanks = ({
  queriesInput,
  recordsInput,
  filterChainsInput,
  monitorsInput,
  licensePriceMonthly,
  licensePriceYearly,
  packageName,
  bpName,
}: thanksProps): ReactElement => {
  const intl = useIntl();
  const [showThanksModal, setShowThanksModal] = useState(true);
  const titleThanks: thanksTable[] = [
    { group: 'title', value: intl.formatMessage(messages.thank_you) },
    {
      group: 'subTitle',
      value: intl.formatMessage(messages.received_text),
    },
  ];

  const widgetTitleThanks: thanksTable[] = [
    {
      group: 'widgetTitle1',
      value: intl.formatMessage(messages.req_detail),
    },
  ];

  const contentThanks: thanksTable[] = [
    {
      group: String(intl.formatMessage(messages.package_label)),
      value: packageName,
    },
    {
      group: String(intl.formatMessage(messages.thankyou_queries_text)),
      value: String(queriesInput),
    },
    {
      group: String(intl.formatMessage(messages.thankyou_records_text)),
      value: String(recordsInput),
    },
    {
      group: String(intl.formatMessage(messages.thankyou_filterchains_text)),
      value: String(filterChainsInput),
    },
    {
      group: String(intl.formatMessage(messages.thankyou_monitors_text)),
      value: String(monitorsInput),
    },
  ];

  packageName === 'Essentials' &&
    contentThanks.push({
      group: String(intl.formatMessage(messages.estimated_text)),
      value: licensePriceMonthly,
    });

  packageName === 'Essentials' &&
    contentThanks.push({
      group: String(intl.formatMessage(messages.estimated_annual_text)),
      value: licensePriceYearly,
    });

  bpName &&
    contentThanks.push({
      group: String(intl.formatMessage(messages.bp_title)),
      value: bpName,
    });

  const buttonThanks: thanksTable[] = [
    {
      group: 'button1',
      value: String(intl.formatMessage(messages.return_est_text)),
    },
  ];

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(true);
  }, [queriesInput, recordsInput, filterChainsInput, monitorsInput]);
  return (
    <ThanksModal
      showThanksModal={showModal}
      pageTitle="NS1 Pricing Page"
      object="Pricing Page"
      productTitle={'NS1 Price Estimator'}
      pageUrl={'/products/ns1-connect/pricing/'}
      location={'Estimator'}
      titleThanks={titleThanks}
      widgetTitleThanks={widgetTitleThanks}
      contentThanks={contentThanks}
      buttonThanks={buttonThanks}
      setShowThanksModal={setShowThanksModal}
    />
  );
};

export default Thanks;
