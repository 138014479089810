/**
 * converts the lang form (cc-cl) to the HTML form (cl-CC)
 *
 * @param {string} i18nextLang in form cc-cl
 * @returns {string} html lang
 */
export default function (lang: string): string {
  if (lang === 'de-de') return 'de-DE';
  if (lang === 'uk-en') return 'en-GB';

  const parts = lang.split('-');

  // use en as fallback if something goes wrong
  if (parts.length !== 2) return 'en-US';

  // return html compatible lang code: https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/lang
  return `${parts[1]}-${parts[0].toUpperCase()}`;
}
