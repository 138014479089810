import { useEffect, useState } from 'react';
import { EST_DEF_QUERIES } from '../constants/spread';
import { getListPrice } from 'src/common/hooks/widgets/fetchData';
import { useCurrencyWithSymbol } from 'src/common/hooks/widgets/useCurrencyWithSymbol';

/**
 * Calculate the estimated price
 *
 * @param {object} intl used for the formatting
 * @param {string} currency the language code to get the currency sign for
 * @param {number} employees : Number of employees/workstations
 * @param {boolean} breach : Includes Breach
 * @returns {Array}
 */

export interface PropsFunc {
  currency: string;
  country: string;
  queriesInput: number;
  recordsInput: number;
  filterChainsInput: number;
  monitorsInput: number;
  tileValueSelected: string;
  localeCode: string;
}

export interface IFetchError {
  status: number;
  statusText: string;
}

export interface priceProps {
  partNumber: string;
  pricingIndicator: string;
  pricingModel: number;
  scaleQtyPrice: [
    {
      qty: number;
      price: number;
    },
  ];
}

const partsToQueryEssentials = [
  {
    partNumber: 'D0IJYZX',
  },
  {
    partNumber: 'D0IK1ZX',
  },
];

type MarketPlaceRequest = {
  currency: string;
  countryCode3: string;
  parts: typeof partsToQueryEssentials;
};

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const usePrice = ({
  currency,
  country,
  queriesInput,
  recordsInput,
  filterChainsInput,
  monitorsInput,
  tileValueSelected,
  localeCode,
}: PropsFunc) => {
  const [totalCost, setTotalCost] = useCurrencyWithSymbol(
    currency,
    localeCode,
    0,
  );
  const [totalCostMonthly, setTotalCostMonthly] = useCurrencyWithSymbol(
    currency,
    localeCode,
    0,
  );
  const [dataFetched, setDataFetched] = useState([]);
  const [fetchingError, setFetchingError] = useState({});
  const [loading, setLoading] = useState(false);

  const setPricesDataEssentials = (products: priceProps[]) => {
    let price = 0;
    if (products?.length) {
      const priceArray = products.map((item) => ({
        [item.partNumber]: item.scaleQtyPrice,
      }));
      const LPDefault = priceArray[0].D0IJYZX;
      const LPAdditional = priceArray[1].D0IK1ZX;
      if (queriesInput === EST_DEF_QUERIES) {
        price = LPDefault[0]?.price;
      } else {
        const add = (queriesInput - EST_DEF_QUERIES) / 10;
        price = LPDefault[0]?.price + LPAdditional[0].price * add;
      }
      return Number(price);
    }
  };

  const fetchPrices = () => {
    setLoading(true);
    const body: MarketPlaceRequest = {
      currency: currency,
      countryCode3: country,
      parts: partsToQueryEssentials,
    };
    getListPrice(
      `https://api.marketplace.ibm.com/purchase/catalog/resources/price/info`,
      JSON.stringify(body),
      'POST',
    )
      .then((data) => {
        if (data.parts && data.parts.length !== 0) {
          setLoading(false);
          setDataFetched(data.parts);
          const price = setPricesDataEssentials(data.parts);
          setTotalCost(Number(price) * 12);
          setTotalCostMonthly(Number(price));
        } else {
          setLoading(false);
          setDataFetched([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        setDataFetched([]);
        if (error.response) {
          setFetchingError(error.response);
        }
      });
  };

  useEffect(() => {
    if (currency && dataFetched.length === 0) {
      fetchPrices();
    }
  }, [currency]);

  useEffect(() => {
    const price = setPricesDataEssentials(dataFetched);
    setTotalCost(Number(price) * 12);
    setTotalCostMonthly(Number(price));
  }, [
    queriesInput,
    recordsInput,
    filterChainsInput,
    monitorsInput,
    currency,
    tileValueSelected,
  ]);

  return [totalCost, totalCostMonthly, loading, fetchingError];
};
