/**
 * Segment tool to track user action
 *
 * @param {string} eventName name of the event
 * @param {string} lang current lang
 * @param {object} eventData details of the event
 * @param {string} eventData.pageTitle title of the current page
 * @param {string} eventData.object url of the current pageThe object that the action is associated with
 * @param {string} eventData.resultValue short error or success description to be defined by the instrumenting team
 * @param {string} eventData.CTA the call to action, only for cta clicked event
 * @param {string} eventData.location location in the UI
 * @param {string} eventData.action action did on UI, only for user form event
 * @param {string} eventData.field field name, only for user form event
 *
 */

export interface segmentProperties {
  productTitle?: string;
  pageTitle: string;
  object: string;
  pageUrl?: string;
  successFlag?: boolean;
  resultValue: string;
  CTA: string;
  location: string;
  action: string;
  field: string;
}

export const segmentsTracking = (
  eventName: string,
  {
    pageTitle,
    object,
    resultValue,
    CTA,
    location,
    action,
    field,
  }: segmentProperties,
) => {
  window?.bluemixAnalytics?.trackEvent(eventName, {
    productTitle: 'Planning Analytics SaaS Price Estimator',
    pageTitle,
    pageUrl: '/products/planning-analytics/pricing/',
    object,
    successFlag: true,
    resultValue,
    CTA,
    location,
    action,
    field,
  });
};
