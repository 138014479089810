import { ResolvedIntlConfig } from 'react-intl';
import fetchCOSTranslationMessages from './fetchCOSTranslationMessages';
import mapValidLocale, { IBMLocale } from './mapValidLocale';

export type AEMPromise = Promise<Record<string, string>>;

function getLocale(locale: string): string {
  switch (locale) {
    case IBMLocale.DE:
      return 'de-de';
    case IBMLocale.ES:
      return 'es-es';
    case IBMLocale.ESLA:
      // Handle 'esla' as 'es-es'
      return 'es-mx';
    case IBMLocale.FR:
      return 'fr-fr';
    case IBMLocale.IT:
      return 'it-it';
    case IBMLocale.JA:
      return 'jp-ja';
    case IBMLocale.KO:
      return 'kr-ko';
    case IBMLocale.PT:
      return 'br-pt';
    case IBMLocale.ID:
      return 'id-id';
    case IBMLocale.AR:
      // to handle arabic verbiages
      return 'sa-ar';
    case IBMLocale.ZH_CN:
      // Handle 'zh-cn' as 'cn-zh'
      return 'cn-zh';
    default:
      console.warn(`Unknown Widget language code: ${locale}`);
      return '';
  }
}

const fetchAEMTranslationMessages = async (
  widgetId: string,
  langCode: string,
): Promise<ResolvedIntlConfig['messages']> => {
  const locale = mapValidLocale(langCode);
  console.log(`Retrieving AEM Language for: ${widgetId}`);

  const url =
    locale === IBMLocale.EN
      ? `https://www.ibm.com/widgets-admin/${widgetId}/jcr:content.widget.json`
      : `https://www.ibm.com/${getLocale(
          locale,
        )}/widgets-admin/${widgetId}/jcr:content.widget.json`;

  const fetchTranslations = async (
    url: string,
  ): Promise<ResolvedIntlConfig['messages']> => {
    try {
      const response = await window.fetch(url);
      if (!response.ok) {
        throw new Error(
          `AEM translations HTTP error! Status: ${response.status}`,
        );
      }
      return await response.json();
    } catch (error) {
      console.error(`Error fetching translations from ${url}:`, error);
      console.info(`Falling back to COS translations for: ${widgetId}`);
      return fetchCOSTranslationMessages(widgetId, locale);
    }
  };

  return fetchTranslations(url);
};

export default fetchAEMTranslationMessages;
