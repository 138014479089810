export const ENV_AMOUNT = 1;
export const DISCOUNT_USER = 0.5;
export const DISCOUNT_RAM = 0.9;
export const DISCOUNT_INSTANCE_STD = 0.66;
export const DISCOUNT_INSTANCE_EST = 0.66;
export const RAM = 16;
export const YEARLY = 12;
export const TOTAL_COST_LIMIT = 100000;
export const EST_DEF_QUERIES = 30;
export const EST_MIN_QUERIES = 30;
export const EST_MAX_QUERIES = 90;
export const FREE_DEF_QUERIES = 5;
export const FREE_MIN_QUERIES = 5;
export const FREE_MAX_QUERIES = 6;
export const PREM_DEF_QUERIES = 90;
export const PREM_MIN_QUERIES = 90;
export const PREM_MAX_QUERIES = 500;
export const EST_DEF_RECORDS = 3000;
export const EST_MIN_RECORDS = 3000;
export const EST_MAX_RECORDS = 3001;
export const FREE_DEF_RECORDS = 500;
export const FREE_MIN_RECORDS = 500;
export const FREE_MAX_RECORDS = 501;
export const PREM_DEF_RECORDS = 3000;
export const PREM_MIN_RECORDS = 3000;
export const PREM_MAX_RECORDS = 10000;
export const EST_DEF_FILTERCHAINS = 5;
export const EST_MIN_FILTERCHAINS = 5;
export const EST_MAX_FILTERCHAINS = 6;
export const FREE_DEF_FILTERCHAINS = 1;
export const FREE_MIN_FILTERCHAINS = 1;
export const FREE_MAX_FILTERCHAINS = 2;
export const PREM_DEF_FILTERCHAINS = 5;
export const PREM_MIN_FILTERCHAINS = 5;
export const PREM_MAX_FILTERCHAINS = 10000;
export const EST_DEF_MONITORS = 20;
export const EST_MIN_MONITORS = 20;
export const EST_MAX_MONITORS = 21;
export const FREE_DEF_MONITORS = 1;
export const FREE_MIN_MONITORS = 1;
export const FREE_MAX_MONITORS = 2;
export const PREM_DEF_MONITORS = 20;
export const PREM_MIN_MONITORS = 20;
export const PREM_MAX_MONITORS = 10000;
export const EST_THRESH_QUERIES = 60;
export const STD_THRESH_QUERIES = 4;
export const PREM_THRESH_QUERIES = 300;
export const EST_THRESH_RECORDS = 7000;
export const STD_THRESH_RECORDS = 700;
export const PREM_THRESH_RECORDS = 7000;
export const EST_THRESH_FILTERCHAINS = 4;
export const STD_THRESH_FILTERCHAINS = 1;
export const PREM_THRESH_FILTERCHAINS = 40;
export const EST_THRESH_MONITORS = 15;
export const STD_THRESH_MONITORS = 1;
export const PREM_THRESH_MONITORS = 80;

export const PREM_MIN_RAM = 64;

export const PAPkg = {
  pkg1: 'Essentials',
  pkg2: 'Free Developer',
  pkg3: 'Premium',
};

export const userRange = {
  estRange: 10,
  stdRange1: 99,
  stdRange2: 93,
  stdRange3: 87,
  stdRange4: 81,
  stdRange5: 75,
  stdRange6: 69,
  stdRange7: 63,
};

export const ramRange = {
  estRange: 16,
  stdRange1: 32,
  stdRange2: 48,
  stdRange3: 64,
  stdRange4: 80,
  stdRange5: 96,
  stdRange6: 112,
  stdRange7: 128,
};

// UT30 is an unique code to identify each widget in the BPPR ecosystem
export const UT30PASaaS = '30AGF';
