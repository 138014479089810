import { useState, useEffect, useCallback } from 'react';

/**
 * Hook to check if a max-width is reached
 *
 * @param {number} width searched max-width value
 * @returns {boolean} max-width reached or not
 */
const useMediaQuery = (width: string): boolean => {
  const [targetReached, setTargetReached] = useState(false);

  const updateTarget = useCallback((e) => {
    if (e.matches) {
      setTargetReached(true);
    } else {
      setTargetReached(false);
    }
  }, []);

  useEffect(() => {
    const media = window.matchMedia(`(max-width: ${width})`);
    if (media.addEventListener) {
      media.addEventListener('change', (e) => updateTarget(e));
    }
    if (media.matches) {
      setTargetReached(true);
    }
    return () => {
      if (media.removeEventListener) {
        media.removeEventListener('change', (e) => updateTarget(e));
      }
    };
  }, []);

  return targetReached;
};

/**
 * useSmallBreakpoint
 *
 * @param {string} breakpoint px size of the desired breakpoint
 * @returns {boolean} true if small breakpoint is active
 */
export const useSmallBreakpoint = (breakpoint: string): boolean =>
  useMediaQuery(breakpoint);
