import { defineMessages } from 'react-intl';

const messages = defineMessages({
  ns1_headline: {
    id: 'WZw8pU',
    defaultMessage:
      'Start exploring IBM NS1 Connect’s pricing for your unique requirements.',
  },
  ns1_sub_headline: {
    id: 'H7Z+W8',
    defaultMessage:
      'Flexible, modular and scalable options designed for organizations at any stage of their authoritative DNS mangement journey.',
  },
  number_queries_text: {
    id: 'UrDIHR',
    defaultMessage: 'DNS queries per month (in millions)',
  },
  number_records_text: {
    id: '5jkVtY',
    defaultMessage: 'DNS records',
  },
  number_filterchains_text: {
    id: 'nGv1a7',
    defaultMessage: 'Traffic Steering Filter Chains',
  },
  number_monitors_text: {
    id: 'xJrRMG',
    defaultMessage: 'Monitors',
  },
  thankyou_queries_text: {
    id: '43X37u',
    defaultMessage: 'Number of DNS Queries per month (Millions)',
  },
  thankyou_records_text: {
    id: '3Tg/gA',
    defaultMessage: 'Number of DNS Records per month',
  },
  thankyou_filterchains_text: {
    id: '0gaCXb',
    defaultMessage: 'Number of Filter Chains',
  },
  thankyou_monitors_text: {
    id: 'ddQ1hA',
    defaultMessage: 'Number of Monitors',
  },
  tooltip_queries_text: {
    id: '7Oa43h',
    defaultMessage:
      'Input the estimated number DNS Queries to your domain names.',
  },
  tooltip_records_text: {
    id: 'YJ5PzU',
    defaultMessage: 'Input your number of fully qualified domain names.',
  },
  tooltip_filterchain_text: {
    id: 'DV0EwT',
    defaultMessage:
      'Input the number of records that require traffic steering.',
  },
  tooltip_monitors_text: {
    id: 'nFZiSa',
    defaultMessage: 'Input your number host names/IP that you monitor.',
  },
  std_notif_txt: {
    id: 'gfGcw4',
    defaultMessage: 'Go to Essentials',
  },
  more_info_text: {
    id: 'y6FZ6y',
    defaultMessage: 'More Information',
  },
  prem_records_info2: {
    id: '0lk5pd',
    defaultMessage: '- can add unlimited DNS records',
  },
  prem_records_info1: {
    id: 'ZjYIkR',
    defaultMessage: 'Includes 3000 records. Configurable to unlimited.',
  },
  est_feat_pt1: {
    id: 'iIk4by',
    defaultMessage: 'DNSSEC support',
  },
  std_feat_pt3: {
    id: '8tT/iB',
    defaultMessage: '26 anycast PoPs',
  },
  std_feat_pt4: {
    id: 'g9WLCP',
    defaultMessage: 'Secondary DNS ',
  },
  tooltip_ram_text: {
    id: 'y1BCJF',
    defaultMessage: 'Adequate RAM is required based on expected usage.',
  },
  std_feat_pt1: {
    id: 'oGTxBg',
    defaultMessage: 'Managed DNS',
  },
  est_feat_pt3: {
    id: 'ViSKWf',
    defaultMessage: '24/7/365 expert DNS support',
  },
  disclaimer_text: {
    id: 'rD6XxA',
    defaultMessage:
      'Estimated costs are not a formal offer from IBM or an IBM Business Partner. Certain factors such as Planning Analytics configuration, add-ons, and discounts may affect estimated costs. IBM Business Partners set and provide their own IBM Planning Analytics pricing, and neither IBM nor IBM Business Partners are bound by the provided estimate.',
  },
  est_feat_pt2: {
    id: 'Rxvhe3',
    defaultMessage: 'Unlimited API calls',
  },
  std_feat_pt2: {
    id: '0HCwr/',
    defaultMessage: 'DDoS protection',
  },
  est_feat_pt4: {
    id: 'edZYua',
    defaultMessage: '100% resolution SLA',
  },
  est_feat_pt5: {
    id: 'brtZtw',
    defaultMessage: 'Zone Versioning',
  },
  user_text: {
    id: 'EwRIOm',
    defaultMessage: 'User',
  },
  std_records_info1: {
    id: 'DF5g4q',
    defaultMessage: 'Includes up to 500 DNS records',
  },
  estimated_text: {
    id: '9NxZjj',
    defaultMessage: 'Estimated Monthly Cost',
  },
  est_records_info1: {
    id: 'jv3kzV',
    defaultMessage: 'Includes 3000 DNS records',
  },
  std_invalid_records_text: {
    id: 'UztYT0',
    defaultMessage: 'Please provide records equal to 500',
  },
  std_feat_label: {
    id: '+j5BV9',
    defaultMessage: 'All the features of the Free Developer, plus',
  },
  prem_feat_label: {
    id: 'mB2mq1',
    defaultMessage: 'All the features of the Essentials, plus',
  },
  prem_pkg_desc: {
    id: 'u+E0dg',
    defaultMessage:
      'Leverage the scalability of IBM’s authoritative DNS and grade up with add-ons like RUM-based traffic steering, dedicated DNS instance, and more.',
  },
  std_label: {
    id: 'FKC0Df',
    defaultMessage: 'Free Developer',
  },
  prem_label: {
    id: 'C5xzTC',
    defaultMessage: 'Premium',
  },
  env_text: {
    id: 'K7kuIZ',
    defaultMessage: 'Environment',
  },
  est_pkg_desc: {
    id: 'O3pcdM',
    defaultMessage:
      'Ideal for growing companies looking to improve DNS performance with a premium DNS.',
  },
  col1_text: {
    id: 'FCjGve',
    defaultMessage: 'Requirement',
  },
  thank_you: {
    id: 'aYIUar',
    defaultMessage: 'Thank you!',
  },
  bp_title: {
    id: 'BkHBGW',
    defaultMessage: 'Selected Business Partner',
  },
  prem_queries_info1: {
    id: 'ORu4ZZ',
    defaultMessage: 'Includes 90M queries. Configurable to unlimited.',
  },
  estimated_annual_text: {
    id: 'EocqPl',
    defaultMessage: 'Estimated Annual Cost',
  },
  prem_queries_info2: {
    id: 'juSKlJ',
    defaultMessage: '- can add unlimited DNS queries',
  },
  prem_notif_label: {
    id: 'vt9nHB',
    defaultMessage: 'Information notification',
  },
  no: {
    id: 'oUWADl',
    defaultMessage: 'No',
  },
  return_est_text: {
    id: 'ppYuQ0',
    defaultMessage: 'Return to the product page',
  },
  est_invalid_records_text: {
    id: 'vzvT3Y',
    defaultMessage: 'Please provide records equal to 3000',
  },
  prem_invalid_queries_text: {
    id: 'TfliiQ',
    defaultMessage: 'Please provide records equal to or more than 90 million',
  },
  prem_invalid_records_text: {
    id: 'xYUMs9',
    defaultMessage: 'Please provide queries equal to or more than 3000',
  },
  prem_invalid_filter_chains_text: {
    id: 'Fxub+P',
    defaultMessage: 'Please provide filter chains equal to or more than 5',
  },
  prem_invalid_monitors_text: {
    id: '9QjwFr',
    defaultMessage: 'Please provide monitors equal to or more than 20',
  },
  recommend_text: {
    id: 'aX8wEI',
    defaultMessage: 'Need additional capacity or advanced capability?',
  },
  prcie_text: {
    id: 'cVxiy4',
    defaultMessage: 'Prices shown do not include tax',
  },
  bp_desc: {
    id: '9k14Pe',
    defaultMessage:
      'I consent to IBM providing my business contact information: ',
  },
  contact_info: {
    id: 'R6Ef/+',
    defaultMessage: 'For a solution of this size please get in touch with us.',
  },
  est_label: {
    id: 'eXbuB9',
    defaultMessage: 'Essentials',
  },
  close_btn_text: {
    id: 'rbrahO',
    defaultMessage: 'Close',
  },
  std_invalid_queries_text: {
    id: 'nE7Xkq',
    defaultMessage: 'Please provide queries equal to 5 million',
  },
  std_invalid_filter_chains_text: {
    id: '5enV2g',
    defaultMessage: 'Please provide filter chains qual to 1',
  },
  std_invalid_monitors_text: {
    id: '6T/YIM',
    defaultMessage: 'Please provide monitors equal to 1',
  },
  est_invalid_filter_chains_text: {
    id: 'yUmZY2',
    defaultMessage: 'Please provide filter chains equal to 5',
  },
  est_invalid_monitors_text: {
    id: 'C5+PxE',
    defaultMessage: 'Please provide monitors equal to 20',
  },

  est_queries_info1: {
    id: 'WAtPv+',
    defaultMessage: 'Includes 30M queries. Configurable to 80M.',
  },
  est_filter_chains_info1: {
    id: 'L7cO/4',
    defaultMessage: 'Includes 5 filter chains',
  },
  est_monitors_info1: {
    id: 'R1tqOp',
    defaultMessage: 'Includes 20 monitors',
  },
  std_filter_chains_info1: {
    id: 'tsZmWr',
    defaultMessage: 'Includes 1 traffic steering filter chain',
  },
  std_monitors_info1: {
    id: 'u5HvyW',
    defaultMessage: 'Includes 1 monitor',
  },
  prem_filter_chains_info1: {
    id: 'fAaW2B',
    defaultMessage: 'Includes 5 filter chains. Configurable to unlimited.',
  },
  prem_filter_chains_info2: {
    id: 'ehVqKA',
    defaultMessage: '- can add unlimited filter chains',
  },
  prem_monitors_info1: {
    id: 'c2e63C',
    defaultMessage: 'Includes 20 monitors. Configurable to unlimited.',
  },
  prem_monitors_info2: {
    id: 'QUSs1k',
    defaultMessage: '- can add unlimited monitors',
  },
  std_pkg_desc: {
    id: 'FDR76d',
    defaultMessage:
      'Perfect for small teams, up and running in minutes, no time limit.',
  },
  gb_text: {
    id: 'LRu6Sn',
    defaultMessage: 'GB',
  },
  est_feat_label: {
    id: 'ZXp0z1',
    defaultMessage: 'Features',
  },
  more_info_desc1: {
    id: 'bBbB9F',
    defaultMessage:
      'Your IBM Planning Analytics SaaS price depends on the numbers of users and RAM utilized. The base package starts at 10 users and 32 GB RAM.',
  },
  more_info_desc2: {
    id: 'YuLQT+',
    defaultMessage:
      'Using this info, we can give you an estimate of what your monthly cost might look like based on what similar organizations are paying. For an accurate and customized quote, our specialists are always here to help.',
  },
  prem_feat_pt2: {
    id: 'MHQmS/',
    defaultMessage: 'HTTPS URL Redirects',
  },
  prem_feat_pt3: {
    id: 'brtZtw',
    defaultMessage: 'Zone Versioning',
  },
  prem_feat_pt4: {
    id: 'P1SHWA',
    defaultMessage: 'Custom Configurations',
  },
  prem_feat_pt5: {
    id: 'quGMNT',
    defaultMessage: 'SSO Support',
  },
  prem_notif_txt: {
    id: '5M/GvW',
    defaultMessage: 'Go to Premium',
  },
  req_detail: {
    id: 'v/FY4E',
    defaultMessage: 'Details:',
  },
  quote_text: {
    id: 'r4tIGb',
    defaultMessage: 'Request a quote',
  },
  prem_feat_pt1: {
    id: 'Oi70S1',
    defaultMessage: 'HTTPS Redirects',
  },
  package_label: {
    id: 'RcNFA2',
    defaultMessage: 'Package',
  },
  yes: {
    id: 'a5msuh',
    defaultMessage: 'Yes',
  },
  col2_text: {
    id: 'qVGRIE',
    defaultMessage: 'Quantity',
  },
  std_user_info2: {
    id: 'fzJK6z',
    defaultMessage: '- can add individual users',
  },
  received_text: {
    id: 'LyvG/S',
    defaultMessage:
      'We have received your request and a member of our NS1 team will contact you shortly.',
  },
  std_queries_info1: {
    id: '2YPixQ',
    defaultMessage: 'Includes up to 5,000,000 DNS queries.',
  },
  book_text: {
    id: 'r4tIGb',
    defaultMessage: 'Request a quote',
  },
  more_info_heading: {
    id: 'vCcCFd',
    defaultMessage: 'Planning Analytics as a Service',
  },
  est_invalid_queries_text: {
    id: 'WR+ue2',
    defaultMessage: 'Please provide queries range between 30-80 million',
  },
  martketplace_button: {
    id: 'OeUkjL',
    defaultMessage: 'Buy now on IBM.com',
  },
  demo_button: {
    id: 'xSsVfT',
    defaultMessage: 'Book a live demo',
  },
  aws_button: {
    id: '6oJwp/',
    defaultMessage: 'Buy now on AWS',
  },
  advanced_options_title: {
    id: 'meRvZz',
    defaultMessage: 'Advanced Functionality - Available in Premium',
  },
  rum_toggle: {
    id: 'CM5fJq',
    defaultMessage: 'RUM-based traffic steering',
  },
  https_redirect_toggle: {
    id: '3k7k0t',
    defaultMessage: 'Real-time detailed metrics',
  },
  outage_protection_toggle: {
    id: 'FbovtP',
    defaultMessage: '3-12 dedicated PoPs',
  },
  preformance_china_toggle: {
    id: 'Hf6Sng',
    defaultMessage: 'DNS for China',
  },
  signup_free_btn: {
    id: 'Rtxm5T',
    defaultMessage: 'Sign up for free',
  },
  book_consultation: {
    id: 'KTmxQU',
    defaultMessage: 'Book a consultation',
  },
  big_blue_30: {
    id: 'ylewKh',
    defaultMessage:
      "Get 30% off IBM's industry leading authoritative DNS service when you buy through IBM Marketplace during the month of November.",
  },
  big_blue_30_short: {
    id: 'SBwEje',
    defaultMessage: 'Get 30% off when you buy in November.',
  },
  oc_request_sub_heading_quote: {
    id: 'h9Pch+',
    defaultMessage: 'Request a quote or live demo!',
  },
  oc_request_sub_heading_buy_now: {
    id: 'uCpdSL',
    defaultMessage: 'Find the purchase option that matches your needs.',
  },
  oc_request_desc: {
    id: 'fVmKCn',
    defaultMessage:
      'IBM NS1 Connect Managed DNS and traffic steering plans are priced based upon query volume and DNS records. Our NS1 Essentials plan is available through IBM Marketplace and AWS Marketplace. To learn more about our enterprise options, contact sales. We also offer a developer account at no charge for companies looking to try the solution first.',
  },
  oc_request_quote_desc: {
    id: 'Zsy2ft',
    defaultMessage:
      'Engage with our sales team to obtain answers to your questions, discuss your specific requirement, and receive a personalized quote',
  },
  oc_request_demo_desc: {
    id: 'JDfAox',
    defaultMessage:
      'Ready to see a demo in action? Click the "Request a demo button to get started!',
  },
  oc_cta_request_live_demo: {
    id: 'olK/t4',
    defaultMessage: 'Request a live demo',
  },
  ibm_desc: {
    id: 'saypmI',
    defaultMessage:
      'Buy online from IBM in just a few clicks, completing your order with a credit card and get access to your software within minutes.',
  },
  aws_desc: {
    id: 'jGHHe3',
    defaultMessage:
      'If you are already an AWS customer, buy through AWS to use your existing credits.',
  },
});

export default messages;
