import { FormattedMessage, MessageDescriptor } from 'react-intl';
/**
 * This function provides proper translations based on environment using the provided message object.
 *
 * @param {string} id - The ID of the message to translate.
 * @param {boolean} isString - The isString helps to return string value instead of JSX.Element
 * @param {object} [messagesFromCOS={}] - An object containing messaes fetched from COS (messages.ts).
 * @param {object} [messagesFromAEM={}] - An object containing messages fetched from AEM.
 * @returns {string | JSX.Element} - The translated message.
 */

export type TranslationFunction = (
  id: string,
  isString?: boolean,
  values?: valObj,
) => string | JSX.Element;

interface valObj {
  [key: string]: string;
}
interface Messages {
  [key: string]: string;
}
const replacePlaceholders = (str: string, values: valObj) => {
  return str.replace(/{(.*?)}/g, (match, key) => {
    return values[key] || match; // Replace the placeholder with the value or leave it if the key doesn't exist
  });
};

export const convertToString = (translated: string | JSX.Element) => {
  return translated as string;
};

export const createUseTranslation = (
  messages: Record<string, MessageDescriptor>,
  messagesFromAEM: Messages,
): TranslationFunction => {
  const useTranslation: TranslationFunction = (id, isString, values) => {
    const isEmpty = (obj: Messages) => Object.keys(obj).length === 0;
    if (isEmpty(messagesFromAEM)) {
      if (isString) {
        return messages[id].defaultMessage as string;
      } else if (typeof values === 'object') {
        return replacePlaceholders(
          messages[id].defaultMessage as string,
          values,
        );
      } else {
        return <FormattedMessage {...messages[id]} />;
      }
    } else {
      if (typeof values === 'object' && messagesFromAEM[id] != undefined) {
        return replacePlaceholders(messagesFromAEM[id] as string, values);
      } else {
        return messagesFromAEM[id];
      }
    }
  };
  return useTranslation;
};
