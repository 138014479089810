import { Button } from 'carbon-components-react';
import '../../../../common/style/common.scss';
import { ArrowRight16 } from '@carbon/icons-react';
import { IBMLocale } from 'src/common/locale/mapValidLocale';
import { segmentsTracking } from 'src/common/lib/tracker';
import { TranslationFunction } from 'src/common/hooks/widgets/useTranslation';
import NoteDrawerRequest from './NoteDrawerRequest/NoteDrawerRequest';
import { useEffect, useState } from 'react';
import { ProductInterface } from 'src/apps/omnichannel-panel/components/Omnichannel';
import { OMNICHANNEL_HEADER } from 'src/apps/omnichannel-panel/constants/default';

export interface OmniChannelProps {
  id: string;
  omniChannel: boolean;
  setUrxForm: React.Dispatch<React.SetStateAction<boolean>>;
  setOmniChannel: React.Dispatch<React.SetStateAction<boolean>>;
  environment: string;
  instanceId: string;
  formId: string;
  urxlang: IBMLocale;
  bpid?: string;
  pageTitle: string;
  object: string;
  location: string;
  resultValue?: string;
  ctaValue?: string;
  action?: string;
  field?: string;
  tiles: string;
  setCookie: () => void;
  useTranslation: TranslationFunction;
  localeCode: string;
  ddoCountry: string;
}

const OmniChannel = ({
  id,
  setUrxForm,
  omniChannel,
  setOmniChannel,
  pageTitle,
  setCookie,
  useTranslation,
  environment,
  instanceId,
  object,
  tiles,
  urxlang,
  location,
  localeCode,
  ddoCountry,
}: OmniChannelProps) => {
  const [product, setProduct] = useState<ProductInterface>();

  // // to fetch OC product details from COS
  const initOCConfig = (productTitleFetched: string) => {
    fetch(
      `https://ace-project-bucket.s3.us-east.cloud-object-storage.appdomain.cloud/${
        window.location.host === 'www.ibm.com' ? 'production' : 'test'
      }/omnichannel.json`,
      {
        method: 'GET',
        headers: OMNICHANNEL_HEADER,
      },
    )
      .then((res) => res.json())
      .then((data) =>
        setProduct(
          data.find(
            (product: ProductInterface) =>
              product?.active &&
              product?.productTitle
                ?.toLowerCase()
                .match(productTitleFetched.toLowerCase()),
          ),
        ),
      )
      .catch((error) => {
        console.error(
          'error while fetching omnichannel products via ace cos bucket',
          error,
        );
      });
  };

  useEffect(() => {
    // intializing the OC component with fetched productTitle
    initOCConfig('NS1-Connect');
  }, []);

  return (
    <div className="omni-channel">
      <Button
        kind="primary"
        renderIcon={ArrowRight16}
        id="request_btn"
        data-button="omnichannel"
        onClick={() => {
          segmentsTracking('CTA Clicked', {
            pageTitle: pageTitle,
            object: 'Pricing page',
            resultValue: 'CTA clicked',
            CTA: 'omnichannel slider closed',
            location: 'Estimator',
            action: '',
            field: '',
          });
          setOmniChannel(true);
          setUrxForm(false);
          setCookie();
        }}
      >
        Buy now
        <span
          data-href="https://www.ibm.com/marketplace/purchase/configuration/en/us/checkout?editionID=EIDF9I49&pricingtablecta=true"
          data-label="Buy now"
          data-offering="Buy now"
          data-widget-editionid="EIDF9I49"
        ></span>
      </Button>
      {product && (
        <NoteDrawerRequest
          id={id}
          omniChannelRequest={omniChannel}
          setOmniChannelRequest={setOmniChannel}
          setUrxForm={setUrxForm}
          environment={environment}
          instanceId={instanceId}
          formId={''}
          formId2={''}
          moreinformation={false}
          urxlang={urxlang}
          bpid={''}
          pageTitle={pageTitle}
          object={object}
          location={location}
          tiles={tiles}
          setCookie={setCookie}
          useTranslation={useTranslation}
          isBuyNow={true}
          product={product}
          localeCode={localeCode}
          ddoCountry={ddoCountry}
          editionId="EIDF9I49"
        />
      )}
    </div>
  );
};

export default OmniChannel;
