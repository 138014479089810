import React, { useEffect, useState } from 'react';
import { Button, Tag } from 'carbon-components-react';
import { ArrowRight16, Launch16, Subtract32 } from '@carbon/icons-react';
import './NoteDrawerRequest.scss';
import '../../NS1PriceEstimator.scss';
import { initUrxForm } from 'src/common/hooks/widgets/urxForm';
import { IBMLocale } from 'src/common/locale/mapValidLocale';
import { segmentsTracking } from 'src/common/lib/tracker';
import { TranslationFunction } from 'src/common/hooks/widgets/useTranslation';
import PublicImage from 'src/common/components/PublicImage';
import { FormattedMessage } from 'react-intl';
import messages from 'src/apps/ns1-pe/locales/messages';
import {
  ProductInterface,
  Promotion,
  PromotionText,
} from 'src/apps/omnichannel-panel/components/Omnichannel';
import { v4 as uuidv4 } from 'uuid';

export interface NoteDrawer {
  omniChannelRequest: boolean;
  setOmniChannelRequest: React.Dispatch<React.SetStateAction<boolean>>;
  setUrxForm: React.Dispatch<React.SetStateAction<boolean>>;
  moreinformation: boolean;
  bpName?: string;
  logo?: string;
  id: string;
  environment: string;
  instanceId: string;
  formId: string;
  formId2: string;
  urxlang: IBMLocale;
  bpid?: string;
  pageTitle: string;
  object: string;
  location: string;
  resultValue?: string;
  ctaValue?: string;
  action?: string;
  field?: string;
  tiles: string;
  setCookie: () => void;
  useTranslation: TranslationFunction;
  isBuyNow?: boolean;
  product?: ProductInterface;
  localeCode?: string;
  ddoCountry?: string;
  editionId?: string;
}

export default function NoteDrawer({
  id,
  setUrxForm,
  omniChannelRequest,
  setOmniChannelRequest,
  environment,
  urxlang,
  formId,
  formId2,
  instanceId,
  bpid,
  pageTitle,
  object,
  resultValue = '',
  ctaValue = '',
  action = '',
  field = '',
  location,
  tiles,
  setCookie,
  useTranslation,
  isBuyNow,
  product,
  localeCode = 'us-en',
  ddoCountry = 'us',
  editionId = '',
}: NoteDrawer) {
  const [ibmPromo, setIBMPromo] = useState<Promotion[]>();
  const [awsPromo, setAWSPromo] = useState<Promotion[]>();

  const closeDrawer = () => {
    setOmniChannelRequest(false);
    segmentsTracking('CTA Clicked', {
      pageTitle: pageTitle,
      object: object,
      resultValue: 'CTA clicked',
      CTA: 'ominichannel request slider closed',
      location: location,
      action: action,
      field: field,
    });
  };

  const filterPromotionByCTA = (promotions: Promotion[], cta: string) => {
    return promotions?.filter(
      (promotion) =>
        promotion.status &&
        isPromotionAlive(promotion.startTs, promotion.endTs) &&
        promotion.cta === cta,
    );
  };

  const isPromotionAlive = (startTs: string, endTs: string) => {
    const currentTs = new Date();
    const startTsDate = new Date(startTs);
    const endTsDate = new Date(endTs);
    return currentTs > startTsDate && currentTs < endTsDate;
  };

  const getPromoLabel = (promoText: PromotionText) => {
    const promoLabel = promoText[localeCode];
    return promoLabel === '' || promoLabel === undefined
      ? promoText['us-en']
      : promoLabel;
  };

  useEffect(() => {
    if (omniChannelRequest) {
      segmentsTracking('CTA Clicked', {
        pageTitle: pageTitle,
        object: object,
        resultValue: 'CTA clicked',
        CTA: 'ominichannel request slider opened',
        location: location,
        action: action,
        field: field,
      });
    }

    // Setting promotions
    if (product?.promotions) {
      setAWSPromo(filterPromotionByCTA(product.promotions, 'aws'));
      setIBMPromo(filterPromotionByCTA(product.promotions, 'ibm-marketplace'));
    }
  }, [omniChannelRequest, product, editionId]);

  return (
    <div
      id={id}
      className="ns1_pe_contact_us_omnichannel"
      aria-label="Omnichannel Drawer"
      aria-hidden={!omniChannelRequest}
    >
      <div
        className={`widget-drawer-backdrop ${
          omniChannelRequest ? 'opened' : 'closed'
        } widget-drawer-transition`}
        onClick={closeDrawer}
      ></div>
      <div
        className={`widget-drawer ${
          omniChannelRequest ? 'opened' : 'closed'
        } widget-drawer-transition`}
      >
        <div className="widget-drawer-inner-container">
          <div className="bx--row">
            <div className="bx--col-lg-16 bx--col-md-16 bx--col-sm-16">
              <div className="widget-drawer-close-container">
                <Button
                  className="widget-drawer-close-btn"
                  kind="ghost"
                  renderIcon={Subtract32}
                  onClick={closeDrawer}
                ></Button>
              </div>
            </div>
          </div>
          <div className="widget-drawer-notes-container">
            <div className="bx--row">
              <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
                <p className="slide_text widgetname">IBM NS1 Connect</p>
              </div>
              <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
                <p className="page_sub_heading">
                  {isBuyNow ? (
                    <FormattedMessage
                      {...messages.oc_request_sub_heading_buy_now}
                    />
                  ) : (
                    <FormattedMessage
                      {...messages.oc_request_sub_heading_quote}
                    />
                  )}
                </p>
              </div>
              <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4 mt-02 pr-02">
                <p className="page_body">
                  {useTranslation('oc_request_desc') ? (
                    useTranslation('oc_request_desc')
                  ) : (
                    <FormattedMessage {...messages.oc_request_desc} />
                  )}
                </p>
              </div>

              {isBuyNow ? (
                <>
                  {product?.offering &&
                    product?.offering[editionId]?.marketplace && (
                      <div className="cta-section">
                        <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
                          {/* For future notifications */}
                        </div>
                        <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
                          <PublicImage
                            className="img_logo"
                            imgPath="images/ibm.png"
                            widgetId="omnichannel-panel"
                            alt="ibm-mktplace-image"
                          />
                        </div>
                        <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4 cta">
                          <div className="bx--col-lg-10 bx--col-md-5 bx--col-sm-4 cta-desc">
                            <p className="page_body cta-text">
                              <FormattedMessage {...messages.ibm_desc} />
                            </p>
                            {ibmPromo?.map((promo) => (
                              <Tag
                                key={uuidv4()}
                                type="green"
                                size="md"
                                disabled={false}
                                filter={false}
                              >
                                {getPromoLabel(promo.text)}
                              </Tag>
                            ))}
                          </div>
                          <div className="bx--col-lg-6 bx--col-md-3 bx--col-sm-2 cta-button">
                            <Button
                              className="omnichannel-panel__bluehighlight"
                              kind="tertiary"
                              renderIcon={Launch16}
                              id="ibm-marketplace_btn"
                              onClick={() => {
                                segmentsTracking('CTA Clicked', {
                                  pageTitle: pageTitle,
                                  object: object,
                                  resultValue: 'CTA clicked - Omnichannel',
                                  CTA: 'ibm marketplace',
                                  location: location,
                                  action: action,
                                  field: field,
                                  subscriptionId: editionId,
                                  productPlanName:
                                    product?.offering[editionId].name,
                                });
                                setTimeout(async () => {
                                  window.open(
                                    product?.offering[
                                      editionId
                                    ].ecomm?.includes(ddoCountry)
                                      ? `https://www.ibm.com/store/en/us/products/${editionId}`
                                      : `https://www.ibm.com/marketplace/purchase/configuration/en/checkout?editionID=${editionId}`,
                                    '_blank',
                                  );
                                }, 250);
                              }}
                            >
                              <FormattedMessage
                                {...messages.martketplace_button}
                              />
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  {product?.offering && product?.offering[editionId]?.aws && (
                    <div className="cta-section">
                      <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
                        <PublicImage
                          className="img_logo"
                          imgPath="images/aws.png"
                          widgetId="omnichannel-panel"
                          alt="aws-image"
                        />
                      </div>
                      <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4 cta">
                        <div className="bx--col-lg-10 bx--col-md-5 bx--col-sm-4 cta-desc">
                          <p className="page_body cta-text">
                            <FormattedMessage {...messages.aws_desc} />
                          </p>
                          {awsPromo?.map((promo) => (
                            <Tag
                              key={uuidv4()}
                              type="purple"
                              size="md"
                              disabled={false}
                              filter={false}
                            >
                              {getPromoLabel(promo.text)}
                            </Tag>
                          ))}
                        </div>
                        <div className="bx--col-lg-6 bx--col-md-3 bx--col-sm-2 cta-button">
                          <Button
                            className="omnichannel__bluehighlight"
                            kind="tertiary"
                            renderIcon={Launch16}
                            id="aws_btn"
                            onClick={() => {
                              segmentsTracking('CTA Clicked', {
                                pageTitle: pageTitle,
                                object: object,
                                resultValue: 'CTA clicked - Omnichannel',
                                CTA: 'aws marketplace',
                                location: location,
                                action: action,
                                field: field,
                                subscriptionId: editionId,
                                productPlanName:
                                  product?.offering[editionId].name,
                              });
                              setTimeout(async () => {
                                window.open(
                                  product.offering[editionId].aws,
                                  '_blank',
                                );
                              }, 250);
                            }}
                          >
                            {useTranslation('aws_button')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="cta-section">
                    <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
                      <PublicImage
                        className="img_logo cta-consult-seller-img"
                        imgPath="images/chat.svg"
                        widgetId="omnichannel-panel"
                        alt="chat-image"
                      />
                    </div>
                    <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4 cta">
                      <div className="bx--col-lg-10 bx--col-md-5 bx--col-sm-4 cta-desc">
                        <p className="page_body cta-text">
                          <FormattedMessage
                            {...messages.oc_request_quote_desc}
                          />
                        </p>
                      </div>
                      <div className="bx--col-lg-6 bx--col-md-3 bx--col-sm-2 cta-button">
                        <Button
                          renderIcon={ArrowRight16}
                          id="request_btn_quote"
                          onClick={() => {
                            segmentsTracking('CTA Clicked', {
                              pageTitle: pageTitle,
                              object: object,
                              resultValue: 'CTA clicked - Omnichannel request',
                              CTA: 'request a quote',
                              location: location,
                              action: action,
                              field: field,
                            });
                            initUrxForm(
                              environment,
                              instanceId,
                              formId,
                              urxlang as IBMLocale,
                              bpid,
                            );
                            setUrxForm(true);
                            setOmniChannelRequest(false);
                            setCookie();
                          }}
                        >
                          {useTranslation('quote_text')}
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className="cta-section">
                    <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
                      <PublicImage
                        className="img_logo cta-consult-seller-img"
                        imgPath="images/chat.svg"
                        widgetId="omnichannel-panel"
                        alt="chat-image"
                      />
                    </div>
                    <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4 cta">
                      <div className="bx--col-lg-10 bx--col-md-5 bx--col-sm-4 cta-desc">
                        <p className="page_body cta-text">
                          <FormattedMessage
                            {...messages.oc_request_demo_desc}
                          />
                        </p>
                      </div>
                      <div className="bx--col-lg-6 bx--col-md-3 bx--col-sm-2 cta-button">
                        <Button
                          kind="primary"
                          renderIcon={ArrowRight16}
                          id="request_btn_demo"
                          onClick={() => {
                            segmentsTracking('CTA Clicked', {
                              pageTitle: pageTitle,
                              object: object,
                              resultValue: 'CTA clicked - Omnichannel request',
                              CTA: 'request a demo',
                              location: location,
                              action: action,
                              field: field,
                            });
                            initUrxForm(
                              environment,
                              instanceId,
                              formId2,
                              urxlang as IBMLocale,
                              bpid,
                            );
                            setUrxForm(true);
                            setOmniChannelRequest(false);
                            setCookie();
                          }}
                        >
                          <FormattedMessage
                            {...messages.oc_cta_request_live_demo}
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
