module.exports = {
  entry: './src/apps/ns1-pe/index.tsx',
  shortcode: 'ns1-pe',
  title: 'NS1 Price Estimator',
  status: 'stable',
  description: 'Allows users to determine a price.',
  preview: {
    url: `${process.env.PUBLIC_URL}/storybook/iframe.html?id=widgets-ns1-pe-components--default&viewMode=story`,
  },
  useExternalPeerDependencies: ['react', 'react-dom', 'react-intl'],
  additionalCustomProperties: {
    webSegmentPaths: ['/cloud'],
    availableTranslations: [
      'de',
      'en',
      'es',
      'esla',
      'fr',
      'it',
      'ja',
      'ko',
      'pt',
      'zh-cn',
    ],
    org: 'digitalsales', // to filter the widget
    brand: '', // automation, dataai, security, sustainabilty, storage, other
    widgetLocation: '/pricing', // default '/pricing' but sometimes '/'
    productName: 'ns1-connect', // the product name in the URL
    activatedCountryPages: ['us-en'],
  },
};
