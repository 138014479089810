import { useState, useEffect } from 'react';
import lngToHtmlLang from '../../lib/lngToHtmlLang';

const formatCurrenySymbol = (symbol: string, currency: string) => {
  switch (currency) {
    case 'AUD':
      return 'AU$ ';
    case 'CAD':
      return 'CA$ ';
    case 'NZD':
      return 'NZ$ ';
    case 'USD':
      return 'USD ';
    default:
      return symbol + ' ';
  }
};

/**
 * Format price
 * @param price
 * @param numberFormat
 * @param currency
 * @returns
 */
const formatPrice = (
  price: number,
  numberFormat: Intl.NumberFormat,
  currency: string,
) => {
  const formattedValue = numberFormat.formatToParts(price);
  return formattedValue
    .map(({ type, value }) =>
      type === 'currency' ? formatCurrenySymbol(value, currency) : value,
    )
    .reduce((string, part) => string + part);
};

const formatCurrency = (
  price: number,
  numberFormat: Intl.NumberFormat,
  currency: string,
): string => {
  if (currency) {
    return formatPrice(price, numberFormat, currency);
  } else {
    return numberFormat.format(price);
  }
};

export const useCurrencyWithSymbol = (
  intl: string,
  lang: string,
  price: number,
): [string, React.Dispatch<React.SetStateAction<number>>] => {
  const [rawValue, setRawValue] = useState(price);
  const [formattedValue, setFormattedValue] = useState('');

  useEffect(() => {
    if (rawValue === null || intl == null || intl.length === 0) {
      setFormattedValue('');
    } else {
      const currency = intl;
      const numberFormat = new Intl.NumberFormat(lngToHtmlLang(lang), {
        style: 'currency',
        currency,
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
      });

      const formatted = formatCurrency(rawValue, numberFormat, currency);
      setFormattedValue(formatted);
    }
  }, [lang, rawValue, intl]);

  return [formattedValue, setRawValue];
};
