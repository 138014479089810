import React, { ReactElement } from 'react';
import 'src/common/style/common.scss';

export interface FootnoteProps {
  numNote: number;
}

export default function Footnote({ numNote }: FootnoteProps): ReactElement {
  return (
    <p>
      <span className="footnote_num">{numNote}</span>
    </p>
  );
}
