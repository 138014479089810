const apiFetch = async (
  url: string,
  opts: RequestInit = {},
  consumeBody = true,
  accessToken?: string,
) => {
  if (typeof opts.body === 'object') {
    opts.body = JSON.stringify(opts.body);
  }
  const { headers: headers_opts, ...rest_opts } = opts;
  return await fetch(url, {
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : '',
      'Content-Type': 'application/json',
      'Response-Type': 'application/json',
      ...headers_opts,
    },
    ...rest_opts,
  }).then((fetchResult) => {
    if (!consumeBody) return fetchResult;
    return fetchResult.json();
  });
};

export interface responseProperties {
  status: number;
  message: string;
}

/**
 * Obtain List price from remote API Price Service
 * @param url
 * @param country
 * @param currency
 * @param parts
 * @param method
 * @returns
 */
export const getListPrice = async (
  url: string,
  body: string,
  method: string,
) => {
  let newRows = [];
  newRows = await apiFetch(url, {
    body,
    method,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
  return newRows;
};

export const executeRequest = async (
  url: string,
  body: string,
  method: string,
): Promise<responseProperties> => {
  let newRows = {} as responseProperties;
  let status = 200;
  newRows = await apiFetch(
    url,
    {
      body,
      method,
    },
    false,
  )
    .then((response) => {
      status = response.status;
      return response.json();
    })
    .then((message) => {
      const results = {
        status,
        message,
      };
      return results;
    })
    .catch((error) => {
      console.error(error);
      return {
        status: 500,
        message: error.message,
      };
    });
  return newRows;
};

export const executeNoBodyRequest = async (
  url: string,
  method: string,
  headers?: Record<string, never>,
): Promise<responseProperties> => {
  let newRows = {} as responseProperties;
  let status = 200;
  newRows = await apiFetch(
    url,
    {
      method,
      headers,
    },
    false,
  )
    .then((response) => {
      status = response.status;
      return response.json();
    })
    .then((message) => {
      const results = {
        status,
        message,
      };
      return results;
    })
    .catch((error) => {
      console.error(error);
      return {
        status: 500,
        message: error.message,
      };
    });
  return newRows;
};
